import { DOCUMENT, isPlatformBrowser, ViewportScroller } from '@angular/common';
import { AfterViewInit, ApplicationRef, Component, inject, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, ActivationEnd, NavigationEnd, Router, RouterEvent, RouterOutlet, Scroll } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import AOS from 'aos';
import { delay, filter } from 'rxjs';
import { environment } from '../environments/environment';
import { ChangeLanguageBarComponent } from './modules/shared/change-language-bar/change-language-bar.component';
import { FooterComponent } from './modules/shared/footer/footer.component';
import { NavigationComponent } from './modules/shared/navigation/navigation.component';
import { NavigationService } from './services/navigation.service';
import { PreviewService } from './modules/preview/preview.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [TranslateService],
    standalone: true,
    imports: [ChangeLanguageBarComponent, NavigationComponent, RouterOutlet, FooterComponent]
})
export class AppComponent implements OnInit, AfterViewInit {
    private platformId = inject<Object>(PLATFORM_ID);
    private previewService = inject(PreviewService);
    displayPrismicSharing = this.previewService.displaySharing;
    prismicShareUrl = this.previewService.shareUrl;
    prismicPreviewUid = this.previewService.shareUid;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private translate: TranslateService,
        private meta: Meta,
        @Inject(DOCUMENT) private document: Document,
        private route: ActivatedRoute,
        private viewportScroller: ViewportScroller,
        private navigationService: NavigationService,
        private appRef: ApplicationRef
    ) {
        this.setupInternalization();
        this.router.events
            .pipe(
                filter((e: any): e is Scroll => e instanceof Scroll),
                delay(0)
            )
            .subscribe((e: any) => {
                if (e.position) {
                    viewportScroller.scrollToPosition(e.position);
                } else if (e.anchor) {
                    try {
                        const element = document.querySelector(`#${e.anchor}`);
                        if (element) {
                            element.scrollIntoView({ behavior: 'auto', block: 'start' });
                        }
                    } catch (e) {}
                }
            });
    }

    ngAfterViewInit() {
        if (isPlatformBrowser(this.platformId)) {
            this.appRef.isStable.subscribe((isStable) => {
                if (isStable) {
                    AOS.refresh();
                }
            });
        }
    }

    ngOnInit() {
        this.saveFirstVisitPage();
        this.navigationService.isFlyoutOpen.next(false);

        if (isPlatformBrowser(this.platformId)) {
            AOS.init({
                duration: 1000,
                once: true,
                offset: 100
            });
        }

        this.router.events.pipe(filter((event: any) => event instanceof NavigationEnd)).subscribe((event: RouterEvent) => {
            this.navigationService.isThemeChanged.next(false);
            if (this.prismicPreviewUid() && !event.url.includes(this.prismicPreviewUid()!)) {
                this.displayPrismicSharing.set(false);
            }
            if (this.prismicPreviewUid() && event.url.includes(this.prismicPreviewUid()!)) {
                this.displayPrismicSharing.set(true);
            }
        });
        if (environment.stage === 'DEV') {
            this.meta.addTag({ name: 'robots', content: 'noindex' });
            this.meta.addTag({ name: 'googlebot', content: 'noindex' });
        }
    }

    private saveFirstVisitPage() {
        if (isPlatformBrowser(this.platformId)) {
            if (!localStorage.getItem('firstVisitPage')) {
                localStorage.setItem('firstVisitPage', location.href);
                localStorage.setItem('firstVisitDate', new Date().toLocaleDateString());
            }
        }
    }

    private setupInternalization() {
        this.router.events.subscribe((event) => {
            if (event instanceof ActivationEnd) {
                this.translate.use('de');
            }
        });
    }

    copyToClipboard() {
        navigator.clipboard
            .writeText((environment.stage === 'DEV' ? 'http://localhost:4200' : environment.baseUrl) + this.prismicShareUrl()!)
            .then(() => {
                console.log('Text copied to clipboard');
            })
            .catch((err) => {
                console.error('Could not copy text: ', err);
            });
    }
}
