import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PrismicDocument } from '@prismicio/client';
import { ApplicationFormData, ApplicationFormService } from '../../services/application-form.service';
import { MetaDataService } from '../../services/meta-data.service';
import { NavigationService } from '../../services/navigation.service';
import { ActionbarSliceComponent } from '../shared/prismic/actionbar-slice/actionbar-slice.component';
import { AdvantagesSliceComponent } from '../shared/prismic/advantages-slice/advantages-slice.component';
import { AllJobsSliceComponent } from '../shared/prismic/all-jobs-slice/all-jobs-slice.component';
import { AnkerSliceComponent } from '../shared/prismic/anker-slice/anker-slice.component';
import { ApplicationFormSliceComponent } from '../shared/prismic/application-form-slice/application-form-slice.component';
import { CatchLineSliceComponent } from '../shared/prismic/catch-line-slice/catch-line-slice.component';
import { ComparisonSliceComponent } from '../shared/prismic/comparison-slice/comparison-slice.component';
import { ContactDetailsSliceComponent } from '../shared/prismic/contact-details-slice/contact-details-slice.component';
import { CurlyArrowSliceComponent } from '../shared/prismic/curly-arrow-slice/curly-arrow-slice.component';
import { FaqSliceComponent } from '../shared/prismic/faq-slice/faq-slice.component';
import { FeaturesSliceComponent } from '../shared/prismic/features-slice/features-slice.component';
import { FullImageSliceComponent } from '../shared/prismic/full-image-slice/full-image-slice.component';
import { FurtherPagesSliceComponent } from '../shared/prismic/further-pages-slice/further-pages-slice.component';
import { HeaderSliceComponent } from '../shared/prismic/header-slice/header-slice.component';
import { HeadlineSliceComponent } from '../shared/prismic/headline-slice/headline-slice.component';
import { ImageAndTextSliceComponent } from '../shared/prismic/image-and-text-slice/image-and-text-slice.component';
import { JobsManualSliceComponent } from '../shared/prismic/jobs-manual-slice/jobs-manual-slice.component';
import { ListSliceComponent } from '../shared/prismic/list-slice/list-slice.component';
import { MinimalHeaderComponent } from '../shared/prismic/minimal-header/minimal-header.component';
import { PartnerCompaniesSliceComponent } from '../shared/prismic/partner-companies-slice/partner-companies-slice.component';
import { PinkBlockSliceComponent } from '../shared/prismic/pink-block-slice/pink-block-slice.component';
import { PrivacyEtrackerComponent } from '../shared/prismic/privacy-etracker/privacy-etracker.component';
import { ProcessSliceComponent } from '../shared/prismic/process-slice/process-slice.component';
import { ProsAndConsSliceComponent } from '../shared/prismic/pros-and-cons-slice/pros-and-cons-slice.component';
import { QuizSliceComponent } from '../shared/prismic/quiz-slice/quiz-slice.component';
import { RatingsSliceComponent } from '../shared/prismic/ratings-slice/ratings-slice.component';
import { ReleaseNotesSliceComponent } from '../shared/prismic/release-notes-slice/release-notes-slice.component';
import { StepsSliceComponent } from '../shared/prismic/steps-slice/steps-slice.component';
import { TableOfContentSliceComponent } from '../shared/prismic/table-of-content-slice/table-of-content-slice.component';
import { TalentsSliceComponent } from '../shared/prismic/talents-slice/talents-slice.component';
import { TestimonialSliceComponent } from '../shared/prismic/testimonial-slice/testimonial-slice.component';
import { TextSliceComponent } from '../shared/prismic/text-slice/text-slice.component';
import { ThreeColumnsIconTextSliceComponent } from '../shared/prismic/three-columns-icon-text-slice/three-columns-icon-text-slice.component';
import { ThreeColumnsSliceComponent } from '../shared/prismic/three-columns-slice/three-columns-slice.component';
import { TrustBannerSliceComponent } from '../shared/prismic/trust-banner-slice/trust-banner-slice.component';
import { VideoSliceComponent } from '../shared/prismic/video-slice/video-slice.component';
import { StandardPage } from './standard.service';
import {GraduatesSliceComponent} from "../shared/prismic/graduates-slice/graduates-slice.component";

@Component({
    selector: 'app-standard',
    templateUrl: './standard.component.html',
    styleUrls: ['./standard.component.scss'],
    standalone: true,
    imports: [
        HeaderSliceComponent,
        TextSliceComponent,
        HeadlineSliceComponent,
        ThreeColumnsSliceComponent,
        PinkBlockSliceComponent,
        ImageAndTextSliceComponent,
        CatchLineSliceComponent,
        ApplicationFormSliceComponent,
        FaqSliceComponent,
        VideoSliceComponent,
        PartnerCompaniesSliceComponent,
        AdvantagesSliceComponent,
        FullImageSliceComponent,
        MinimalHeaderComponent,
        FeaturesSliceComponent,
        ProcessSliceComponent,
        PrivacyEtrackerComponent,
        ActionbarSliceComponent,
        RatingsSliceComponent,
        TalentsSliceComponent,
        ComparisonSliceComponent,
        FurtherPagesSliceComponent,
        TableOfContentSliceComponent,
        ProsAndConsSliceComponent,
        AnkerSliceComponent,
        StepsSliceComponent,
        ListSliceComponent,
        ContactDetailsSliceComponent,
        QuizSliceComponent,
        AllJobsSliceComponent,
        JobsManualSliceComponent,
        TestimonialSliceComponent,
        ReleaseNotesSliceComponent,
        ThreeColumnsIconTextSliceComponent,
        CurlyArrowSliceComponent,
        TrustBannerSliceComponent,
        GraduatesSliceComponent
    ]
})
export class StandardComponent implements OnInit {
    standardPage!: PrismicDocument<StandardPage>;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private navigationService: NavigationService,
        private applicationFormService: ApplicationFormService,
        private metaDataService: MetaDataService
    ) {}

    ngOnInit() {
        this.route.params.subscribe(() => {
            this.standardPage = this.route.snapshot.data['standardPage'];

            // Update job id
            const jobId = this.standardPage?.data?.job_id;
            const applicationFormData: ApplicationFormData = jobId?.length ? { job: jobId } : {};
            this.applicationFormService.updateApplicationFormData(applicationFormData);

            this.navigationService.theme.next(this.standardPage?.data.is_navbar_pink ? 'Pink' : 'Light');
            this.metaDataService.setupMetaData(
                this.standardPage.data.page_title || '',
                this.standardPage.data.page_description || '',
                this.standardPage
            );
        });
    }
}
