import { Pipe, PipeTransform } from '@angular/core';
import {GraduatesItem} from "./graduates-slice.component";

@Pipe({
  name: 'filterByColumn',
  standalone: true
})
export class FilterByColumnPipe implements PipeTransform {

    transform(items?: GraduatesItem[], column?: number, totalColumns?: number): GraduatesItem[] {
        if (!items || !Array.isArray(items) || !column || !totalColumns || column < 1 || totalColumns < 1) {
            return [];
        }
        return items.filter((_, index) => (index % totalColumns) === (column - 1));
    }

}
