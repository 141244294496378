<app-change-language-bar></app-change-language-bar>
<app-navigation></app-navigation>
<router-outlet></router-outlet>
<app-footer></app-footer>

@if (displayPrismicSharing() && prismicShareUrl()) {
<div class="position-fixed bottom-0 end-0">
    <button (click)="copyToClipboard()" class="btn btn-black">Preview Link kopieren</button>
</div>
}
