import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'addCta',
  standalone: true
})
export class AddCtaPipe implements PipeTransform {

    transform(idx: number, totalCount: number): boolean {
        const midpoint = Math.floor(totalCount / 2);
        if (totalCount % 2 === 0) {
            return idx === midpoint;
        } else {
            return idx === midpoint + 1;
        }
    }

}
