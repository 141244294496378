import { Routes } from '@angular/router';
import { StandardComponent } from './modules/standard/standard.component';
import { standardResolver } from './modules/standard/standard.resolver';

export const routes: Routes = [
    {
        path: '',
        //loadChildren: () => import('./modules/standard/standard.routes').then((mod) => mod.STANDARD_ROUTES),
        component: StandardComponent,
        data: getLocalizeData('de'),
        resolve: {
            standardPage: standardResolver
        }
    },
    {
        path: 'partner',
        loadChildren: () => import('./modules/partner-companies/partner-companies.routes').then((m) => m.PARTNER_COMPANIES_ROUTES),
        data: { uid: 'partner', ...getLocalizeData('de') }
    },
    {
        path: 'bildungspartner',
        loadChildren: () => import('./modules/education-partner/education-partner.routing').then((m) => m.EDUCATION_PARTNER_ROUTES),
        data: { uid: 'bildungspartner', ...getLocalizeData('de') }
    },
    {
        path: 'jobs',
        loadChildren: () => import('./modules/jobs/jobs-routing').then((m) => m.JOB_ROUTES),
        data: getLocalizeData('de')
    },
    {
        path: 'preview',
        loadChildren: () => import('./modules/preview/preview.routing').then((mod) => mod.PREVIEW_ROUTES)
    },
    {
        path: 'artikel',
        loadChildren: () => import('./modules/articles/articles-routing').then((m) => m.ARTICLES_ROUTES),
        data: getLocalizeData('de')
    },
    {
        path: 'kandidaten',
        loadChildren: () => import('./modules/candidates/candidates-routing.module').then((m) => m.CANDIDATE_ROUTES),
        data: getLocalizeData('de')
    },
    {
        path: 'seite-nicht-gefunden',
        loadChildren: () => import('./modules/not-found/not-found-routing').then((mod) => mod.NOT_FOUND_ROUTES),
        data: getLocalizeData('de')
    },
    {
        path: ':uid',
        loadChildren: () => import('./modules/standard/standard.routes').then((mod) => mod.STANDARD_ROUTES),
        data: getLocalizeData('de')
    }
];

function getLocalizeData(language: string): { language: string; locale: string } {
    switch (language) {
        case 'en':
            return {
                language: 'en',
                locale: 'en-us'
            };
        default:
            return {
                language: 'de',
                locale: 'de-de'
            };
    }
}
