import {Component, HostListener, inject, input, OnInit, PLATFORM_ID, signal} from '@angular/core';
import {FilledLinkToMediaField, ImageField, KeyTextField, RichTextField, SelectField, Slice} from "@prismicio/client";
import {RenderHtmlPipe} from "../render-html/render-html.pipe";
import {FilterByColumnPipe} from "./filter-by-column.pipe";
import {isPlatformBrowser, NgTemplateOutlet} from "@angular/common";
import {AddCtaPipe} from "./add-cta.pipe";
import {ApplicationModalService} from "../../application-modal/application-modal.service";

@Component({
  selector: 'app-graduates-slice',
  standalone: true,
    imports: [
        RenderHtmlPipe,
        FilterByColumnPipe,
        NgTemplateOutlet,
        AddCtaPipe
    ],
  templateUrl: './graduates-slice.component.html',
  styleUrl: './graduates-slice.component.scss'
})
export class GraduatesSliceComponent implements OnInit {

    screenWidth: number = 0;
    columns = signal<number>(3);

    private platformId = inject<Object>(PLATFORM_ID);
    private applicationModalService = inject(ApplicationModalService);
    isBrowser = isPlatformBrowser(this.platformId);
    body = input.required<GraduatesSlice>();

    playVideo(videoElement: HTMLVideoElement, playButton: HTMLDivElement): void {
        videoElement.classList.add('active');
        playButton.classList.add('d-none');
        videoElement.play();
    }

    ngOnInit(): void {
        this.updateScreenSize();
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: Event): void {
        this.updateScreenSize();
    }

    private updateScreenSize(): void {
        if (this.isBrowser) {
            this.screenWidth = window.innerWidth;

            if (this.screenWidth > 767) {
                this.columns.set(2);
            }
            if (this.screenWidth > 1200) {
                this.columns.set(3);
            }
            if (this.screenWidth <= 767) {
                this.columns.set(1);
            }
        }
    }

    openApplicationModal(): void {
        this.applicationModalService.openModal();
    }

    protected readonly Math = Math;
}

export type GraduatesSlice = Slice<'graduates',
    {
        cta_title: KeyTextField;
        cta_button_title: KeyTextField;
    },
    GraduatesItem>;

export type GraduatesItem = {
    image: ImageField;
    name: KeyTextField;
    position: KeyTextField;
    video: KeyTextField;
    type: SelectField;
    description: RichTextField;
};
