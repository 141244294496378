@for (section of standardPage?.data.body; track section) {
    <div>
        @switch (section.slice_type) {
            @case ('header') {
                <app-header-slice [body]="section"></app-header-slice>
            }
            @case ('text') {
                <app-text-slice [body]="section"></app-text-slice>
            }
            @case ('ueberschrift') {
                <app-headline-slice [body]="section"></app-headline-slice>
            }
            @case ('3_spalten') {
                <app-three-columns-slice [body]="section"></app-three-columns-slice>
            }
            @case ('pink_block') {
                <app-pink-block-slice [body]="section"></app-pink-block-slice>
            }
            @case ('bild_text') {
                <app-image-and-text-slice [body]="section"></app-image-and-text-slice>
            }
            @case ('schlagzeile') {
                <app-catch-line-slice [body]="section"></app-catch-line-slice>
            }
            @case ('bewerbungsformular') {
                <app-application-form-slice [body]="section"></app-application-form-slice>
            }
            @case ('faq') {
                <app-faq-slice [body]="section"></app-faq-slice>
            }
            @case ('video') {
                <app-video-slice [body]="section"></app-video-slice>
            }
            @case ('partnerunternehmen') {
                <app-partner-companies-slice [body]="section"></app-partner-companies-slice>
            }
            @case ('vorteile') {
                <app-advantages-slice [body]="section"></app-advantages-slice>
            }
            @case ('vollbild') {
                <app-full-image-slice [body]="section"></app-full-image-slice>
            }
            @case ('minimal_header') {
                <app-minimal-header [body]="section" [standardPage]="standardPage"></app-minimal-header>
            }
            @case ('features') {
                <app-features-slice [body]="section"></app-features-slice>
            }
            @case ('prozess') {
                <app-process-slice [body]="section"></app-process-slice>
            }
            @case ('privacy_etracker') {
                <app-privacy-etracker></app-privacy-etracker>
            }
            @case ('actionbar') {
                <app-actionbar-slice [body]="section"></app-actionbar-slice>
            }
            @case ('ratings') {
                <app-ratings-slice [body]="section"></app-ratings-slice>
            }
            @case ('talents') {
                <app-talents-slice [body]="section"></app-talents-slice>
            }
            @case ('comparison') {
                <app-comparison-slice [body]="section"></app-comparison-slice>
            }
            @case ('further_pages') {
                <app-further-pages-slice [body]="section"></app-further-pages-slice>
            }
            @case ('table_of_content') {
                <app-table-of-content-slice [body]="section"></app-table-of-content-slice>
            }
            @case ('pros-and-cons') {
                <app-pros-and-cons-slice [body]="section"></app-pros-and-cons-slice>
            }
            @case ('anker') {
                <app-anker-slice [body]="section"></app-anker-slice>
            }
            @case ('steps') {
                <app-steps-slice [body]="section"></app-steps-slice>
            }
            @case ('list') {
                <app-list-slice [body]="section"></app-list-slice>
            }
            @case ('contact_details') {
                <app-contact-details-slice [body]="section"></app-contact-details-slice>
            }
            @case ('quiz') {
                <app-quiz-slice [body]="section"></app-quiz-slice>
            }
            @case ('all_jobs') {
                @defer {
                    <app-all-jobs-slice></app-all-jobs-slice>
                } @placeholder {
                    <div style="height: 70vh" class="d-flex align-items-center justify-content-center">
                        <span class="fw-bold text-size-m">Jobs werden geladen...</span>
                    </div>
                }
            }
            @case ('jobs_manual') {
                <app-jobs-manual-slice [body]="section"></app-jobs-manual-slice>
            }
            @case ('testimonials') {
                <app-testimonial-slice [body]="section"></app-testimonial-slice>
            }
            @case ('release_notes') {
                <app-release-notes-slice [body]="section"></app-release-notes-slice>
            }
            @case ('three_columns_icon_text') {
                <app-three-columns-icon-text-slice [body]="section"></app-three-columns-icon-text-slice>
            }
            @case ('arrow') {
                <app-curly-arrow-slice></app-curly-arrow-slice>
            }
            @case ('trust_banner') {
                <app-trust-banner-slice></app-trust-banner-slice>
            }
            @case ('graduates') {
                <app-graduates-slice [body]="section"></app-graduates-slice>
            }

        }
    </div>
}
