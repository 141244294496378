<div class="container py-5 py-lg-6">
    <div class="row gy-4 g-lg-5">
        <div class="col-md-6 col-xl-4">
            @for (graduate of body()?.items | filterByColumn : 1:columns(); track $index) {
                <ng-container *ngTemplateOutlet="card; context: {graduate: graduate}"></ng-container>
            }
        </div>

        <div class="col-md-6 col-xl-4">
            @if (body()?.items | filterByColumn : 2:(screenWidth < 768 ? 0 : columns()); as secondCol) {
                @for (graduate of secondCol; track idx; let idx = $index) {
                    <ng-container
                        *ngTemplateOutlet="card; context: {graduate: graduate, idx: idx, totalCount: secondCol?.length}"></ng-container>
                }
            }
        </div>

        <div class="col-md-6 col-xl-4">
            @for (graduate of body()?.items | filterByColumn : 3:(screenWidth < 1200 ? 0 : columns()); track $index) {
                <ng-container *ngTemplateOutlet="card; context: {graduate: graduate}"></ng-container>
            }
        </div>
    </div>
</div>

<ng-template #card let-grd="graduate" let-idx="idx" let-total="totalCount">
    @if (idx | addCta : total) {
        <div class="mb-5">
            <div class="card background-pink px-4 pt-6 pb-5 py-xxl-5 position-relative">
                <img class="position-absolute end-0 top-0 icon-big me-4 mt-4" src="assets/images/plus-white.svg" alt="Plus">
                <p class="text-size-l color-white mb-0">{{body()?.primary?.cta_title}}</p>
                <button class="btn btn-black w-100 mt-4" (click)="openApplicationModal()">{{body()?.primary?.cta_button_title}}</button>
            </div>
        </div>
    }
    <div class="mb-5">
        @switch (grd?.type) {
            @case ('Bewertung') {
                <div class="card p-4">
                    <img class="rating" [src]="grd?.image?.Bewertung?.url" [alt]="grd?.image?.alt">
                    <p class="text-size-m mt-4">{{ grd?.name }}</p>

                    <img class="my-4" src="assets/images/quote.svg" alt="Zitat">

                    <div [innerHtml]="grd?.description | renderHtml"></div>
                </div>
            }
            @case ('Video') {
                <div class="video-card card position-relative"
                     [style.background-image]="'url(' + grd?.image?.url + ')'">
                    <div class="video-container w-100 h-100">
                        <video class="w-100 h-100 object-fit-cover z-1" controls #videoPlayer>
                            <!--<source [src]="grd?.video?.url" type="video/mp4">-->
                            <source [src]="'/assets/videos/' + grd?.video" type="video/mp4" />
                        </video>
                    </div>
                    <div class="position-absolute bottom-0 px-4 d-flex justify-content-between w-100" #playButton>
                        <div>
                            <p class="text-size-m color-white mb-0">{{ grd?.name }}</p>
                            <p class="color-white fw-bold">{{ grd?.position }}</p>
                        </div>
                        <button class="button-unstyled" (click)="playVideo(videoPlayer, playButton)">
                            <img src="assets/images/play.svg" alt="Play">
                        </button>
                    </div>
                </div>
            }
            @case ('Zitat') {
                <div class="card background-pink p-4">
                    <img class="mb-4" src="assets/images/quote-white.svg" alt="Zitat">

                    <div [innerHtml]="grd?.description | renderHtml"></div>
                    <div class="d-flex justify-content-between w-100 align-items-end">
                        <div>
                            <p class="text-size-m color-white mb-0">{{ grd?.name }}</p>
                            <p class="color-white fw-bold mb-0">{{ grd?.position }}</p>
                        </div>
                        <img [src]="grd?.image?.Avatar?.url" [alt]="grd?.image?.alt" class="rounded-circle"
                             height="80px" width="80px">
                    </div>
                </div>
            }
            @default {

            }
        }
    </div>
</ng-template>
